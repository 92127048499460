/* eslint-disable no-param-reassign, no-unused-expressions, unicorn/prefer-query-selector, prefer-rest-params, no-sequences, no-implicit-coercion, no-undef */
(function(i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    (i[r] =
        i[r] ||
        function() {
            (i[r].q = i[r].q || []).push(arguments);
        }),
        (i[r].l = 1 * new Date());
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
})(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

ga('create', 'UA-7555952-14', 'auto');
ga('send', 'pageview');
ga(function() {
    onGoogleAnalyticsLoaded && onGoogleAnalyticsLoaded();
});
/* eslint-enable */
